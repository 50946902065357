// AdminLTEInputPlugin.js
import input from '../components/inputs/InputComponent.vue';
import file from '../components/inputs/FileInputComponent.vue';
import quantity from '../components/inputs/QuantityComponent.vue';
import select from '../components/inputs/SelectComponent.vue';
import button from '../components/ButtonComponent.vue';
import checkbox from '../components/inputs/CheckboxComponent.vue';
import radio from '../components/inputs/RadioComponent.vue';
import checkboxSimple from '../components/inputs/CheckboxSimpleComponent.vue';
import SectionComponent from '@/components/SectionComponent.vue';
import FieldsetComponent from '@/components/FieldsetComponent.vue';
import PaginatedTableComponent from '@/components/PaginatedTableComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import DateComponent from '@/components/inputs/DateComponent.vue';
import Datepicker from 'vue3-datepicker'

import CollapseTransition from '@/components/Transitions/CollapseTransition.vue';
import VueDatePicker from '@vuepic/vue-datepicker';


export default {
	install: (app) => {
		app.component('datepicker-component', Datepicker);
		app.component('collapse-transition', CollapseTransition);
		app.component('input-component', input);
		app.component('file-component', file);
		app.component('quantity-component', quantity);
		app.component('select-component', select);
		app.component('date-component', DateComponent);
		app.component('button-component', button);
		app.component('checkbox-component', checkbox);
		app.component('radio-component', radio);
		app.component('checkbox-simple-component', checkboxSimple);
		app.component('section-component', SectionComponent);
		app.component('fieldset-component', FieldsetComponent);
		app.component('paginated-table-component', PaginatedTableComponent);
		app.component('modal-component', ModalComponent);
		app.component('card-component', CardComponent);
		app.component('vue-daterangepicker-component', VueDatePicker);
	},
};
