// sessionStoragePlugin.js
export function sessionStoragePlugin(store) {
	// When the store is initialized, check if there's already a session storage
	// and commit the data to the store.
	const savedState = localStorage.getItem('store');
	if (savedState) {
		store.replaceState(JSON.parse(savedState));
	}
	// Subscribe to store updates and save the state to session storage
	store.subscribe((mutation, state) => {
		localStorage.setItem('store', JSON.stringify(state));
	});
}
