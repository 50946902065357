<template>
  <div class="form-group">
    <label v-if="label != ''">{{ $t(label) }}</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <font-awesome-icon icon="calendar" />
        </span>
      </div>
      <datepicker-component
        inputFormat="dd/MM/yyyy"
        class="form-control"
        :class="{
          'is-valid': hasValidation && isValid && touched,
          'is-invalid': hasValidation && !isValid && touched,
        }"
        v-model="value"
        @click="validateField()"
        @input="updateValue($event.target.value)"
        :disabled="disabled"
        :style="style"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasValidation: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Date,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    value: {
      get() {
        return this.val;
      },
      set(newValue) {
        this.val = newValue;
        this.updateValue(newValue);
      },
    },
  },
  methods: {
    updateValue(newValue) {
      //We need to transform the date to a dd/MM/yyyy format
      if (newValue instanceof Date) {
        const day = newValue.getDate();
        const month = newValue.getMonth() + 1;
        const year = newValue.getFullYear();
        newValue = `${day < 10 ? "0" + day : day}/${
          month < 10 ? "0" + month : month
        }/${year}`;
      }
      this.$emit("update:modelValue", newValue);
      this.validateField();
    },
    validateField() {
      this.touched = true;
      if (this.hasValidation) {
        this.isValid = this.val !== "" && this.val !== null;
      }
      return this.isValid;
    },
  },
  data() {
    return {
      val: this.initValue,
      touched: false,
      isValid: false,
      style: {
        "--vdp-selected-bg-color": "rgba(194, 205, 235, 1)",
        "--vdp-hover-bg-color": "rgba(194, 205, 235, 1)",
      },
    };
  },
};
</script>