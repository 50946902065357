<template>
  <div>
    <div
      class="modal fade"
      :class="{ show: show }"
      :id="id"
      tabindex="-1"
      role="dialog"
      data-toggle="modal"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" :class="size" role="document">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <slot name="modal-title">Modal Title</slot>
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="close"
            >
              &times;
            </button>
          </div>

          <!-- Modal Body -->
          <slot name="modal-body">
            <div class="modal-body"></div>
          </slot>

          <!-- Modal Footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">
              {{ $t("acciones.cerrar") }}
            </button>
            <slot name="modal-buttons"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "bsModal",
    },
    show: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: "modal-lg",
    },
  },
  methods: {
    close() {
      this.$emit("update:show", false);
      //emit close event
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal {
  display: none;
  z-index: 1045;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal.show {
  display: block;
}
.modal-xxl {
  max-width: 90%;
}
</style>
