<template>
  <div class="icheck-primary">
    <input
      type="checkbox"
      :id="id"
      :value="value"
      ref="checkbox"
      v-model="modelComputed"
      :checked="checked"
    />
    <label :for="id">{{ $t(label) }}</label>
  </div>
</template>

<script>
export default {
  props: {
    id: { type: String, required: true },
    value: { type: String, required: false },
    label: { type: String, required: true },
    checked: { type: Boolean, default: false },
    separateLabel: { type: Boolean, default: false },
    modelValue: {
      type: Array,
      required: true,
    },
  },
  methods: {},
  computed: {
    modelComputed: {
      get() {
        return this.modelValue.includes(this.value);
      },
      set(checked) {
        if (checked) {
          // Add value to the array if checked
          this.$emit("update:modelValue", [...this.modelValue, this.value]);
        } else {
          // Remove value from the array if unchecked
          this.$emit(
            "update:modelValue",
            this.modelValue.filter((item) => item !== this.value)
          );
        }
      },
    },
  },
};
</script>
<style scoped>
[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
  color: rgba(194, 205, 235, 1);
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 50%;
  background-color: #ffffff;
  transition: background 300ms;
  cursor: pointer;
  margin-right: 2px;
}

/* Pseudo element for check styling */
[type="checkbox"]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #ccd3d8;
}

/* Checked */
[type="checkbox"]:checked {
  background-color: currentcolor;
}

[type="checkbox"]:checked::before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

/* Disabled */
[type="checkbox"]:disabled {
  background-color: #ccd3d8;
  opacity: 0.84;
  cursor: not-allowed;
}

/* IE */
[type="checkbox"]::-ms-check {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #ccd3d8;
}

[type="checkbox"]:checked::-ms-check {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}
label {
  margin-top: 3px;
}
</style>