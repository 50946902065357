<template>
  <fieldset data-quantity="">
    <button type="button" class="sub" @click="decrementQuantity">
      <font-awesome-icon icon="minus" />
    </button>
    <input
      @input="updateValue($event.target.value)"
      :value="modelValue"
      type="number"
      name="quantity"
      pattern="[0-9]+"
    />

    <button type="button" class="add" @click="incrementQuantity">
      <font-awesome-icon icon="plus" />
    </button>
  </fieldset>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    updateValue(value) {
      this.$emit("update:modelValue", parseInt(value));
      this.$emit("update");
    },
    incrementQuantity() {
      let temp = parseInt(this.modelValue);
      if (temp < this.maxValue) {
        this.updateValue(temp + 1);
      }
    },
    decrementQuantity() {
      let temp = parseInt(this.modelValue);
      if (temp > 0) {
        this.updateValue(temp - 1);
      }
    },
  },
};
</script>

<style scoped>
fieldset {
  position: relative;
  max-width: 70px;
}

[data-quantity] input {
  height: 25px;
  padding: 0 5px;
  border-radius: 50px;
  border: 0;
  box-shadow: 0 10px 25px -10px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(194, 205, 235, 1);
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

[data-quantity] input:focus {
  outline: none;
  box-shadow: 0 5px 25px -10px rgba(0, 0, 0, 0.2), 0 0 4px #3fb0ff; /* Allows border radius on focus */
}

[data-quantity] input[type="number"]::-webkit-inner-spin-button,
[data-quantity] input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
[data-quantity] input[type="number"] {
  -moz-appearance: textfield;
}
[data-quantity] button {
  color: rgba(194, 205, 235, 1);
  background-color: transparent;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 3px;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 1.4rem;
  cursor: pointer;
  transition: opacity 0.15s;
  font-size: 13px;
}
[data-quantity] button.add {
  right: 0;
  background-position-x: -2.8rem;
}
</style>
