<template>
  <div class="section">
    <div class="section-header">
      <div href="#" @click="display()" :class="{ hasError: hasError }">
        <span class="float-left">
          <font-awesome-icon :icon="icon" />
        </span>
        <span class="title"
          >{{ $t(label) }}
          <span v-if="hasError" class="hasError">*</span>
        </span>
        <span class="float-right">
          <font-awesome-icon :icon="arrow" />
        </span>
      </div>
    </div>
    <collapse-transition>
      <div class="section-content" v-show="active">
        <slot></slot>
      </div>
    </collapse-transition>
  </div>
</template>
<script>
export default {
  name: "SectionComponent",
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    display() {
      this.active = !this.active;
    },
  },
  computed: {
    arrow() {
      return this.active ? "angle-up" : "angle-down";
    },
  },
};
</script>
<style scoped>
.title {
  margin-left: 10px;
}
.hasError {
  color: red;
}
.section {
  border-top: 1px solid #ccc;
  padding-top: 5px;
  padding-bottom: 5px;
}
a {
  display: block;
  width: 100%;
  color: #495057;
}
.section-content {
  margin-top: 20px;
}
.section-content.active {
  display: block;
}
</style>
