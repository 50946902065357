import { createStore } from 'vuex';
import { sessionStoragePlugin } from './plugins/sessionStoragePlugin';

export default createStore({
	state: {
		cartOrders: [],
	},
	getters: {
		getOrdersInCart: (state) => state.cartOrders,

	},
	mutations: {
		ADD_ORDER: (state, order) => {
			state.cartOrders.push(order);
		},
		REMOVE_ORDER: (state, index) => {
			state.cartOrders.splice(index, 1);
		},
		EMPTY_CART: (state) => {
			state.cartOrders = [];
		},
	},
	actions: {
		addOrder: (context, order) => {
			context.commit('ADD_ORDER', order);
		},
		removeOrder: (context, index) => {
			context.commit('REMOVE_ORDER', index);
		},
		emptyCart: (context) => {
			context.commit('EMPTY_CART');
		},
	},
	plugins: [sessionStoragePlugin],
});
