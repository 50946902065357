<template>
	<slot name="language-items">
		<!-- Spanish flag -->
		<li class="nav-item">
			<a
				class="nav-link"
				:class="{active: currentLanguage === 'es'}">
				<country-flag
					@click="switchLanguage('es')"
					country="es" />
			</a>
		</li>
		<!-- Italian flag -->
		<li class="nav-item">
			<a
				class="nav-link"
				:class="{active: currentLanguage === 'it'}">
				<country-flag
					@click="switchLanguage('it')"
					country="it" />
			</a>
		</li>
		<!-- English flag -->
		<li>
			<a
				class="nav-link"
				:class="{active: currentLanguage === 'en'}">
				<country-flag
					@click="switchLanguage('en')"
					country="gb" />
			</a>
		</li>
	</slot>
</template>

<script>
import CountryFlag from 'vue-country-flag-next';

export default {
	components: {CountryFlag},
	data() {
		return {
			currentLanguage: this.$i18n.locale, // Set default language
		};
	},
	methods: {
		switchLanguage(language) {
			// Switch i18n locale
			this.$i18n.locale = language;
			// Update current language
			this.currentLanguage = language;
		},
	},
};
</script>

<style scoped>
li {
	padding: 0;
	margin: 0;
}
a {
	padding: 0;
	text-align: center;
}
.active {
	background-color: rgba(194, 205, 235, 1) !important;
	color: white;
	border-radius: 2rem;
}
</style>
