export const isEmailValid = (value) => {
    let response = { validation: true, message: '' };
    if (!value || !value.length) {
        response.validation = false;
        response.message = 'errores.campo_vacio';
        return response;
    }
    // Check if email
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        response.validation = false;
        response.message = 'errores.campo_email';
    }
    return response;
};

export const isFieldRequired = (value) => {
    let response = { validation: true, message: '' };
    if (Number.isInteger(value)) {
        return response;
    }

    if (!value || !value.length) {
        response.validation = false;
        response.message = 'errores.campo_vacio';
    }
    return response;
};

export const validate = (value, rules) => {
    let response = { validation: true, message: '' };
    if (rules.required) {
        let result = isFieldRequired(value);
        response.error = result.message;
        response.validation = result.validation;
    }
    if (rules.email) {
        let result = isEmailValid(value);
        response.error = result.message;
        response.validation = result.validation;
    }
    return response;
};