<template>
  <div class="form-group">
    <label v-if="displayLabel" :for="id"
      >{{ $t(label) }} <span v-if="hasValidation" class="text-danger">*</span>
    </label>
    <div class="input-group">
      <!-- Add icon if provided -->
      <div v-if="iconLeft" class="input-group-prepend">
        <span class="input-group-text">
          <font-awesome-icon :icon="iconLeft" />
        </span>
      </div>
      <select
        v-model="value"
        :id="id"
        :name="id"
        :disabled="disabled"
        class="form-control"
        :class="{
          'is-valid': hasValidation && isValid && touched,
          'is-invalid': hasValidation && !isValid && touched,
        }"
        @change="updateValue($event.target.value)"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ $t(option.label) }}
        </option>
      </select>

      <slot name="select-actions"></slot>
      <div v-if="iconRight" class="input-group-append">
        <span class="input-group-text">
          <font-awesome-icon :icon="iconRight" />
        </span>
      </div>
    </div>
    <div v-if="!isValid && touched" class="error invalid-feedback text-left">
      {{ $t(error) }}
    </div>
  </div>
</template>

<script>
import * as validator from "../../plugins/validationPlugin.js";

export default {
  props: {
    hasValidation: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    displayLabel: {
      type: Boolean,
      default: true,
    },
    iconLeft: {
      type: String,
      default: "",
    },
    iconRight: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      touched: false,
      isValid: false,
      error: "",
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.updateValue(newValue);
      },
    },
  },

  methods: {
    updateValue(newValue) {
      this.$emit("update:modelValue", newValue); // Emitting the updated value to parent
      this.$emit("change", newValue); // Emitting the updated value to parent
      this.validateField();
    },
    validateField() {
      this.touched = true;
      if (this.hasValidation) {
        let validationResult = validator.validate(this.value, this.rules);
        this.isValid = validationResult.validation;
        this.error = validationResult.message;
      }
      return this.isValid;
    },
  },
};
</script>

<!-- Add styling -->
<style scoped>
/* Add AdminLTE input styling */
</style>
