<template>
	<div class="form-group">
		<label
			v-if="displayLabel"
			:for="id"
			>{{ $t(label) }}</label
		>
		<div class="input-group">
			<!-- Add icon if provided -->
			<div
				v-if="iconLeft"
				class="input-group-prepend">
				<span class="input-group-text">
					<font-awesome-icon :icon="iconLeft" />
				</span>
			</div>
			<input
				:id="id"
				type="file"
				accept="application/pdf"
				class="form-control"
				:placeholder="$t(placeholder)"
				@click="touched = true"
				@change="updateValue($event.target.files[0])"
				:class="{'is-valid': isValid && touched, 'is-invalid': !isValid && touched}" />
			<div
				v-if="iconRight"
				class="input-group-append">
				<span class="input-group-text">
					<font-awesome-icon :icon="iconRight" />
				</span>
			</div>
		</div>
		<div
			v-if="!isValid && touched"
			class="error invalid-feedback text-left">
			{{ translate(error) }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		displayLabel: {
			type: Boolean,
			default: true,
		},

		placeholder: {
			type: String,
			default: '',
		},
		iconLeft: {
			type: String,
			default: '',
		},
		iconRight: {
			type: String,
			default: '',
		},
		maxSize: {
			type: Number,
			default: 15,
		},
		modelValue: {
			type: [String, Number],
			default: '',
		},
	},
	computed: {
		isValid() {
			return this.validate();
		},
	},
	data() {
		return {
			touched: false,
			error: '',
		};
	},
	methods: {
		translate(text) {
			if (!text) return '';
			return this.$t(text);
		},
		validate() {
			if (this.modelValue !== null) {
				//Check filesize
				let size = this.modelValue.size / 1024 / 1024;
				if (size > this.maxSize) {
					this.error = 'errores.max_size';
					return false;
				}
				return true;
			}
			this.error = 'errores.campo_vacio';
			return false;
		},
		updateValue(value) {
			//Perform validation
			this.$emit('update:modelValue', value);
		},
	},
};
</script>

<!-- Add styling -->
<style scoped>
/* Add AdminLTE input styling */
</style>
