<template>
  <div class="card card-outline card-info">
    <div class="card-header" v-if="displayHeader">
      <slot name="card-header"></slot>
    </div>
    <div class="overlay" v-if="isLoading">
      <font-awesome-icon class="fa-spin fa-2x" icon="sync-alt" />
    </div>
    <div class="card-body">
      <input-component
        v-if="searchable"
        id="busqueda"
        type="text"
        :hasValidation="false"
        :displayLabel="false"
        placeholder="acciones.filtrar_resultados"
        v-model="currentSearch"
        @keyup="currentPage = 1"
        iconLeft="search"
      />
      <div class="table-responsivee">
        <table class="table table-striped">
          <thead>
            <slot name="header"></slot>
            <tr v-if="columnas">
              <th v-for="columna in columnas" :key="columna">
                {{ columna.label }}
                <font-awesome-icon
                  style="cursor: pointer"
                  @click="sortBy(columna.sortKey)"
                  icon="sort"
                  v-if="columna.sortable"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <slot name="body" :data="paginatedData"></slot>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer">
      <pagination-component
        v-if="totalPages > 1"
        v-model:currentPage="currentPage"
        :total-pages="totalPages"
      />
    </div>
  </div>
</template>

<script>
import PaginationComponent from "./PaginationComponent.vue";

export default {
  components: { PaginationComponent },
  props: {
    displayHeader: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    columnas: {
      type: Array,
      required: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    filtro: { type: Object, required: false },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  unmounted() {},
  data() {
    return {
      currentPage: 1,
      currentSearch: "",
      columnaOrdenacion: null,
      order: "asc",
    };
  },
  methods: {
    sortBy(columna) {
      this.order =
        this.columnaOrdenacion === columna
          ? this.order === "asc"
            ? "desc"
            : "asc"
          : "asc";
      this.columnaOrdenacion = columna;
    },
  },
  computed: {
    loading() {
      return this.isLoading;
    },
    filteredData() {
      let filteredData = this.data;
      if (this.filtro) {
        filteredData = [];
        //Iterate over the data
        for (let i = 0; i < this.data.length; i++) {
          let item = this.data[i];
          filteredData.push(item);
          //Iterate over the filter keys
          for (let key in this.filtro) {
            let filter = this.filtro[key];
            if (filter.value && filter.value !== "") {
              if (filter.type === "text") {
                if (
                  !String(item[key])
                    .toLowerCase()
                    .includes(filter.value.toLowerCase())
                ) {
                  filteredData.pop();
                  break;
                }
              } else if (filter.type === "date") {
                let init = filter.value[0];
                let end = filter.value[1];
                //We ignore time
                let date = item[key].split("/");
                let itemDate = new Date(date[2], date[1] - 1, date[0]);
                itemDate.setHours(0, 0, 0, 0);
                init.setHours(0, 0, 0, 0);
                end.setHours(0, 0, 0, 0);
                if (itemDate < init || itemDate > end) {
                  filteredData.pop();
                  break;
                }
              } else if (filter.type === "checkbox") {
                if (!eval(filter.trueWhen)) {
                  filteredData.pop();
                  break;
                }
              }
            }
          }
        }
      }
      if (this.searchable) {
        filteredData = Object.values(filteredData).filter((item) => {
          return Object.values(item).some((value) => {
            return String(value)
              .toLowerCase()
              .includes(this.currentSearch.toLowerCase());
          });
        });
      }

      if (this.columnaOrdenacion) {
        filteredData.sort((a, b) => {
          console.log(a[this.columnaOrdenacion]);
          let aValue = a[this.columnaOrdenacion];
          let bValue = b[this.columnaOrdenacion];
          if (this.order === "asc") {
            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
          }
          if (this.order === "desc") {
            if (aValue < bValue) return 1;
            if (aValue > bValue) return -1;
          }
          return 0;
        });
      }

      return filteredData;
    },
    totalPages() {
      return Math.ceil(Object.values(this.filteredData).length / this.pageSize);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return Object.values(this.filteredData).slice(start, end);
    },
  },
};
</script>
