<template>
  <div class="wrapper">
    <Header />
    <div class="content-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <slot name="content-header"></slot>
        </div>
      </div>
      <!-- Content header -->
      <section class="content">
        <!-- Main content -->
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  components: {
    Header,
  },
};
</script>