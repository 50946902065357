<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Header content -->
    <a href="" class="navbar-brand">
      <img :src="logo" alt="Gametia Biobank" class="brand-image" style="" />
      <span v-if="true" class="brand-text font-weight-light"
        >Gametia Biobank
        <label class="badge badge-primary">Sperm Bank</label>
      </span>
    </a>
    <ul class="navbar-nav">
      <li class="nav-item" v-for="(route, index) in sidebarRoutes" :key="index">
        <router-link
          :to="route.path"
          :class="{ 'nav-link': true, active: isActiveRoute(route) }"
        >
          <font-awesome-icon class="nav-icon" :icon="route.icon" />
          <span>{{ $t("menu." + route.name) }}</span>
        </router-link>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <shopping-cart />
      <language-selection></language-selection>
      <button-component
        :tooltip="$t('acciones.descargar_manual')"
        :loading="descargando"
        @click="descargarManual"
        type="primary"
      >
        <font-awesome-icon icon="book" />
      </button-component>

      <router-link to="/logout" class="nav-link">
        <font-awesome-icon class="nav-icon" icon="power-off" />
      </router-link>
    </ul>
  </nav>
</template>

<script>
import logo from "@/assets/bola_gametia.png";

import LanguageSelection from "@/components/skeleton/LanguageSelection.vue";
import ShoppingCart from "@/components/ShoppingCartComponent.vue";
export default {
  name: "Header-View",
  components: {
    LanguageSelection,
    ShoppingCart,
  },
  data: () => ({
    logo: logo,
  }),
  computed: {
    sidebarRoutes() {
      // Filter out child routes of DashboardLayout
      let routes = this.$router.options.routes.find(
        (route) => route.path === "/"
      ).children;

      // Filter out routes that are not displayable
      routes = routes.filter((route) => this.isRouteDisplayable(route));

      return routes;
    },
  },
  methods: {
    async descargarManual() {
      this.descargando = true;
      //get current locale
      let locale = this.$i18n.locale;
      let pdfBlob = await this.$api.descargarManual(locale);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "guia_plataforma_" + locale + ".pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
      this.descargando = false;
    },
    isRouteDisplayable(route) {
      //Check roles and permissions
      let user = JSON.parse(localStorage.getItem("gametia_user"));
      if (route.meta.roles) {
        const found = user.roles.some((r) => route.meta.roles.indexOf(r) >= 0);
        if (!found) {
          return false;
        }
      }
      // Check if the route is displayable

      if (route.meta.display === undefined) {
        return true;
      }
      return route.meta.display;
    },
    isActiveRoute(route) {
      // Check if the current route matches the provided route
      return this.$route.path === route.path;
    },
  },
};
</script>
<style scoped>
.main-headerr {
  background-color: rgba(194, 205, 235, 1);
}
.brand-image {
  height: 45px !important;
  width: auto;
}
.active {
  background-color: rgba(194, 205, 235, 1) !important;
  color: white !important;
  border-radius: 2rem;
}
.nav-icon {
  padding-right: 5px;
}
</style>
