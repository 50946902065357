<template>
  <button
    v-tooltip="tooltip"
    @click="handleClick"
    :class="['btn', 'btn-' + type, { disabled: loading }]"
  >
    <slot v-if="!loading"></slot>
    <span
      v-if="loading"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
  </button>
</template>

<script>
export default {
  props: {
    tooltip: {
      type: String,
      default: "", // Default tooltip
    },
    type: {
      type: String,
      default: "", // Default button type
    },
    loading: {
      type: Boolean,
      default: false, // Default loading state
    },
    mustConfirm: {
      type: Boolean,
      default: false, // Default confirm state
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClick(event) {
      event.preventDefault();
      if (this.mustConfirm) {
        //Use sweetAlert2 to confirm
        this.$swal({
          text: this.$t("acciones.eliminar.confirmacion"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("acciones.confirmar"),
          cancelButtonText: this.$t("acciones.cancelar"),
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit("onClick");
          }
        });
      } else {
        this.$emit("onClick");
      }
    },
  },
};
</script>

<style scoped>
/* Add AdminLTE button styles */
</style>
