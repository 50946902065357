<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {
  components: {},
};
</script>

<style>
/* Add custom styles here */
</style>
