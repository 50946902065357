<template>
  <div class="form-group">
    <label v-if="displayLabel" :for="id"
      >{{ $t(label) }}
      <span v-if="hasValidation" class="text-danger">*</span>
    </label>
    <div class="input-group">
      <!-- Add icon if provided -->
      <div v-if="iconLeft" class="input-group-prepend">
        <span class="input-group-text">
          <font-awesome-icon :icon="iconLeft" />
        </span>
      </div>
      <input
        :id="id"
        :type="type"
        class="form-control"
        :placeholder="$t(placeholder)"
        v-model="value"
        @click="validateField()"
        @input="updateValue($event.target.value)"
        :class="{
          'is-valid': hasValidation && isValid,
          'is-invalid': !isValid && touched,
        }"
      />
      <div v-if="iconRight" class="input-group-append">
        <span class="input-group-text">
          <font-awesome-icon :icon="iconRight" />
        </span>
      </div>
    </div>
    <div v-if="!isValid && touched" class="error invalid-feedback text-left">
      {{ $t(error) }}
    </div>
  </div>
</template>

<script>
import * as validator from "../../plugins/validationPlugin.js";
export default {
  props: {
    hasValidation: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    displayLabel: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    iconLeft: {
      type: String,
      default: "",
    },
    iconRight: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      touched: false,
      isValid: false,
      error: "",
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.updateValue(newValue);
      },
    },
  },
  methods: {
    updateValue(newValue) {
      this.$emit("update:modelValue", newValue); // Emitting the updated value to parent
      this.validateField();
    },
    validateField() {
      this.touched = true;
      this.isValid = true;
      if (this.hasValidation) {
        let validationResult = validator.validate(this.value, this.rules);
        this.isValid = validationResult.validation;
        this.error = validationResult.message;
      }
      return this.isValid;
    },
  },
};
</script>

<!-- Add styling -->
<style scoped>
/* Add AdminLTE input styling */
</style>
