<template>
	<router-link
		to="checkout"
		class="shopping-cart nav-link float-right mr1">
		<font-awesome-icon icon="shopping-cart" />
		<span class="badge badge-info navbar-badge">{{ items.length }}</span>
	</router-link>
</template>
<style scoped></style>
<script>
import {mapGetters, mapActions} from 'vuex';

export default {
	data() {
		return {};
	},
	computed: {
		...mapGetters(['getOrdersInCart']),
		items() {
			return this.getOrdersInCart;
		},
	},
	methods: {
		...mapActions(['removeOrder']),
	},
};
</script>
