<template>
	<div
		class="card"
		:class="{'collapsed-card': isCollapsed}">
		<div class="card-header">
			<slot name="card-title"></slot>
			<div class="card-tools">
				<slot name="card-tools"></slot>
				<button
					type="button"
					@click="isCollapsed = !isCollapsed"
					class="btn btn-tool">
					<font-awesome-icon :icon="icon" />
				</button>
			</div>
		</div>
		<collapse-transition>
			<div
				class="card-body"
				v-show="!isCollapsed">
				<slot name="body"></slot>
			</div>
		</collapse-transition>
		<div class="card-footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>
<script>
export default {
	components: {},
	props: {
		collapsed: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isCollapsed: this.collapsed,
		};
	},
	computed: {
		icon() {
			return this.isCollapsed ? 'plus' : 'minus';
		},
	},
};
</script>
<style scoped></style>
