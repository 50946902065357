<template>
  <div class="fieldset">
    <h6 v-if="label">{{ $t(label) }}</h6>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
<style scoped>
.fieldset {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}
</style>
